import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import { ComplexSettings, Settings, TokenId } from '../../model'

type LoadSettingsResponse = {
  account_id: number
  deleted: boolean
  id: boolean
  settings: { favorites?: TokenId[]; application?: Settings }
}

export type UpdateSettingsResponse = {
  id: string
  settings: ComplexSettings
  account_id: number
  deleted: boolean
}

// TODO: test flow to remove
export type DeleteSettingsResponse = {
  detail: 'Successfully deleted' // TODO: backend ticket, make response structure as AddFavouriteTokenResponse
}

type AddFavouriteTokenResponse = {
  account_id: number
  deleted: false
  id: number
  settings: ComplexSettings
}

type RemoveTokensToFavouriteListReturn = {
  status: string
}

class SettingsApi {
  async load(): Promise<ProxyApiFetchReturn<LoadSettingsResponse>> {
    const response = await proxyApiFetch<LoadSettingsResponse>(
      environment.getDexGuruAuthApiV1Url(),
      `/settings/`,
      {
        init: { method: 'GET' },
      }
    )
    return response
  }

  async update(
    settingsData: Partial<ComplexSettings>
  ): Promise<ProxyApiFetchReturn<UpdateSettingsResponse>> {
    const response = await proxyApiFetch<UpdateSettingsResponse>(
      environment.getDexGuruAuthApiV1Url(),
      `/settings/`,
      {
        init: { method: 'PUT', body: JSON.stringify(settingsData) },
      }
    )
    return response
  }

  async delete(): Promise<ProxyApiFetchReturn<DeleteSettingsResponse>> {
    const response = await proxyApiFetch<DeleteSettingsResponse>(
      environment.getDexGuruAuthApiV1Url(),
      `/settings/`,
      {
        init: { method: 'DELETE' },
      }
    )
    return response
  }

  async addToFavourites(
    tokenIds: Array<TokenId>
  ): Promise<ProxyApiFetchReturn<AddFavouriteTokenResponse>> {
    const response = await proxyApiFetch<AddFavouriteTokenResponse>(
      environment.getDexGuruAuthApiV1Url(),
      `/settings/favorites`,
      {
        init: { method: 'PATCH', body: JSON.stringify({ token_ids: tokenIds }) },
      }
    )
    return response
  }

  async removeFromFavourites(
    tokenIds: Array<TokenId>
  ): Promise<ProxyApiFetchReturn<RemoveTokensToFavouriteListReturn>> {
    const response = await proxyApiFetch<RemoveTokensToFavouriteListReturn>(
      environment.getDexGuruAuthApiV1Url(),
      `/settings/favorites`,
      {
        init: { method: 'DELETE', body: JSON.stringify({ token_ids: tokenIds }) },
      }
    )
    return response
  }
}

export default SettingsApi
