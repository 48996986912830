import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import { Address, model } from '../../model'
import {
  LimitOrderData,
  LimitOrderEvent,
  LimitOrderSendFailure,
  LimitOrderSendSuccess,
  LimitOrderStruct,
} from '../../model/limitOrderModel'
import { getTokenAddressFromId, getTokenNetworkFromId } from '../../utils'

class LimitOrdersApi {
  async getLimitOrderEvents(
    orderHash: string,
    currentTokenId: model.TokenId
  ): Promise<ProxyApiFetchReturn<LimitOrderEvent[]>> {
    const network = getTokenNetworkFromId(currentTokenId)
    const url = environment.getTradingAPIUrl()
    const eventsResponse = await proxyApiFetch<{ [hash: string]: LimitOrderEvent[] }>(
      url,
      `/${network}/limit/events/${orderHash}?provider=one_inch`
    )
    if (eventsResponse.responseData?.[orderHash]) {
      const events = eventsResponse.responseData[orderHash]
      return { responseData: events }
    }

    if (eventsResponse.error) {
      console.error(
        'getLimitOrderEvents: ',
        eventsResponse.error.message,
        eventsResponse.error.errorData
      )
    }

    return { responseData: [] }
  }

  async getLimitOrdersData(
    account: Address,
    currentTokenId: model.TokenId
  ): Promise<ProxyApiFetchReturn<LimitOrderData[]>> {
    const address = getTokenAddressFromId(currentTokenId)
    const network = getTokenNetworkFromId(currentTokenId)
    const url = environment.getTradingAPIUrl()
    const limitOrdersTakerAsset = await proxyApiFetch<LimitOrderData[]>(
      url,
      `/${network}/limit/address/${account}?page=1&limit=100&statuses=%5B1,2%5D&taker_asset=${address}&provider=one_inch`
    )
    const limitOrdersMakerAsset = await proxyApiFetch<LimitOrderData[]>(
      url,
      `/${network}/limit/address/${account}?page=1&limit=100&statuses=%5B1,2%5D&maker_asset=${address}&provider=one_inch`
    )

    const limitOrdersJoin: ProxyApiFetchReturn<LimitOrderData[]> = {
      responseData: [
        ...(limitOrdersTakerAsset.responseData || []),
        ...(limitOrdersMakerAsset.responseData || []),
      ],
      error: limitOrdersTakerAsset.error || limitOrdersMakerAsset.error,
    }

    if (limitOrdersJoin.error) {
      console.error(
        'getLimitOrdersData: ',
        limitOrdersJoin.error.message,
        limitOrdersJoin.error.errorData
      )
    }

    return limitOrdersJoin
  }

  async sendLimitOrder({
    oneInchApiUrl,
    data,
    signature,
    orderHash,
  }: {
    oneInchApiUrl: string
    data: LimitOrderStruct
    signature: string
    orderHash: string
  }): Promise<ProxyApiFetchReturn<LimitOrderSendSuccess | LimitOrderSendFailure>> {
    const response = await proxyApiFetch<LimitOrderSendSuccess | LimitOrderSendFailure>(
      oneInchApiUrl,
      '',
      {
        init: {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            orderHash,
            signature,
            data,
          }),
        },
      }
    )
    return response
  }
}

export default LimitOrdersApi
