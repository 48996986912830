import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import {
  LastNotification,
  MarkedNotificationResponse,
  NotificationResponse,
} from '../../model/alertsModel'

class AlertsApi {
  async getAll(): Promise<ProxyApiFetchReturn<NotificationResponse>> {
    return await proxyApiFetch<NotificationResponse>(
      environment.getDexGuruAuthApiV3Url(),
      '/notifications',
      {
        init: { method: 'GET' },
      }
    )
  }

  async mark(
    newLastNotification: LastNotification
  ): Promise<ProxyApiFetchReturn<MarkedNotificationResponse>> {
    return await proxyApiFetch<MarkedNotificationResponse>(
      environment.getDexGuruAuthApiV3Url(),
      '/notifications/mark',
      {
        init: {
          method: 'PUT',
          body: JSON.stringify(newLastNotification),
        },
      }
    )
  }
}

export default AlertsApi
