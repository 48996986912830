import { SearchSymbolResultItem } from '../../charting_library/datafeed-api'
import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'

type SearchSymbolsResponse = SearchSymbolResultItem[]

class TVApi {
  async searchSymbols({
    userInput,
    exchange,
    symbolType,
  }: {
    userInput: string
    exchange: string
    symbolType: string
  }): Promise<ProxyApiFetchReturn<SearchSymbolResultItem[]>> {
    return proxyApiFetch<SearchSymbolsResponse>(
      environment.getDexGuruAPIV1Url(),
      `/tradingview/search?query=${userInput.toUpperCase()}&limit=30&type=${symbolType}&exchange=${exchange}`
    )
  }
}

export default TVApi
