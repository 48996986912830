import axios from 'axios'

import { getSessionHeader, HeaderKeys } from '../../helpers/ProxyApiFetch/sessionManager'
import AlertsApi from './AlertsApi'
import LimitOrdersApi from './LimitOrdersApi'
import NotificationsApi from './NotificationsApi'
import SettingsApi from './SettingsApi'
import TokensApi from './TokensApi'
import TransactionsApi from './TransactionsApi'
import TVApi from './TVApi'
import WalletsApi from './WalletsApi'

class ApiClient {
  alerts: AlertsApi = new AlertsApi()
  limitOrders: LimitOrdersApi = new LimitOrdersApi()
  notifications: NotificationsApi = new NotificationsApi()
  settings: SettingsApi = new SettingsApi()
  tokens: TokensApi = new TokensApi()
  tv: TVApi = new TVApi()
  txs: TransactionsApi = new TransactionsApi()
  wallets: WalletsApi = new WalletsApi()

  constructor() {
    axios.defaults.headers.common[HeaderKeys.xSessionId] = getSessionHeader()[HeaderKeys.xSessionId]
  }
}

const apiClient = new ApiClient()

export default apiClient
