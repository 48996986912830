import * as qs from 'query-string'

import { environment } from '../../config/settings'
import { proxyApiFetch } from '../../helpers/ProxyApiFetch'
import { ProxyApiFetchReturn } from '../../helpers/ProxyApiFetch/types'
import {
  AddAlertResponse,
  Alert,
  AlertBody,
  AlertsResponse,
  AlertUpdateBody,
} from '../../model/alertsModel'

class AlertsApi {
  async getAll(offset?: number, limit?: number): Promise<ProxyApiFetchReturn<AlertsResponse>> {
    let url = '/alerts'

    if (offset || limit) {
      url += `?${qs.stringify({ offset, limit })}`
    }

    const response = await proxyApiFetch<AlertsResponse>(
      environment.getDexGuruAuthApiV3Url(),
      url,
      {
        init: { method: 'GET' },
      }
    )
    return response
  }

  async get(id: string): Promise<ProxyApiFetchReturn<Alert>> {
    const response = await proxyApiFetch<Alert>(
      environment.getDexGuruAuthApiV3Url(),
      `/alerts/${id}`,
      {
        init: { method: 'GET' },
      }
    )
    return response
  }

  async add(alert: AlertBody): Promise<ProxyApiFetchReturn<AddAlertResponse>> {
    const response = await proxyApiFetch<AddAlertResponse>(
      environment.getDexGuruAuthApiV3Url(),
      `/alerts`,
      {
        init: { method: 'POST', body: JSON.stringify({ ...alert }) },
      }
    )
    return response
  }

  async update(id: string, alert: AlertUpdateBody): Promise<ProxyApiFetchReturn<Alert>> {
    const response = await proxyApiFetch<Alert>(
      environment.getDexGuruAuthApiV3Url(),
      `/alerts/${id}`,
      {
        init: { method: 'PUT', body: JSON.stringify({ ...alert }) },
      }
    )
    return response
  }

  async delete(id: string): Promise<ProxyApiFetchReturn<Alert>> {
    const response = await proxyApiFetch<Alert>(
      environment.getDexGuruAuthApiV3Url(),
      `/alerts/${id}`,
      {
        init: { method: 'DELETE' },
      }
    )
    return response
  }
}

export default AlertsApi
